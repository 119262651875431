import React from "react";
import ClassSearch from "app/components/Algolia/ClassSearch";
import { useFamilyFriendlyFlag } from "hooks/useFeatureFlags";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import { useResetInitialPagination } from "hooks/useResetInitialPagination";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { AccessType } from "services/graphql";
import { ResultsBodyWrapper } from "../ResultsBodyWrapper";

export default () => {
  const isFamilyFeatureEnabled = useFamilyFriendlyFlag();

  const { resettingPagination } = useResetInitialPagination();

  if (resettingPagination) {
    return <LoaderCentered />;
  }

  return (
    <ResultsBodyWrapper
      contentTitle="Basic Classes"
      contentType="basic-classes"
      indexName={AlgoliaIndexes.classes}
    >
      <ClassSearch
        isFamilyFeatureEnabled={isFamilyFeatureEnabled}
        // We want to show all classes available to Basic users
        refinements={{ accessType: [AccessType.Basic, AccessType.Free] }}
      />
    </ResultsBodyWrapper>
  );
};
